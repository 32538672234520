<script setup lang="ts">
import { HybridSearchResponse } from '@/server/api/ai/HybridSearchResponse';

defineOptions({
	name: 'SearchPage'
})

const SEARCH_ITEMS_ANIMATION_DELAY_MS = 300

// const session = useSupabaseSession()

const searchHistoryState = useSearchHistoryState()
const route = useRoute()
const session = useSupabaseSession()
const supabase = useSupabaseClient()
const { state: userState } = useUserState(
	() => route,
	() => session,
	() => supabase
)
// const { state: userState } = useUserState()

const viewMode = ref<'search-only' | 'results'>('search-only')
const search = ref('')

const filtersInitState = {
	mimeTypes: [],
	date: {
		start: '',
		end: ''
	}
}
const filters = reactive(JSON.parse(JSON.stringify(filtersInitState)))

const _filters = computed(() => {
	if (!filters.mimeTypes?.length && !filters.date.start && !filters.date.end) return undefined

	return {
		file_types: filters.mimeTypes?.length ? filters.mimeTypes : undefined,
		created_after: filters.date.start || undefined,
		created_before: filters.date.end || undefined
	}
})

const { data: generativeSearchData, status: generativeSearchStatus, error: generativeSearchError, execute: generativeSearch } = useStreamPostFetch(
	'/api/ai/generative-search',
	() => ({
		method: 'POST',
		body: {
			query: search.value,
			filters: _filters.value,
			stream: true,
			user_id: session.value.user.id
		},
		server: false,
		immediate: false,
		timeout: 15000
	})
)

type GenerativeJson = {
	answer: string
	sources: Array<{
		asset_id: number
		name: string
		datasource_id: number
		datasource_type: string
		asset_mimetype: string
		asset_author: string
		score: number
	}>
	chunk_asset_map: { [key in string]: number }
	trace_id: string
}
const _formatedData = ref<GenerativeJson | null>(null)
const formattedData = computed<GenerativeJson | null>({
	get() {
		let response = null

		if (_formatedData.value && !generativeSearchData.value) return _formatedData.value
		if (generativeSearchData.value === null) return null

		const arr = generativeSearchData.value.split('data: ')

		try {
			response = JSON.parse(arr.map(str => str.replaceAll(/\n\n/gm, '')).pop())
		} catch (err) {

		}

		return response
	},
	set(val: GenerativeJson) {
		_formatedData.value = val
	}
})

const sortHybridSearchBy = ref<string | undefined>(undefined)
const paginationInitState = {
	perPage: 5,
	exclude: [],
	noMoreResults: false,
	status: 'idle',
}

let paginationTimeout: ReturnType<typeof setTimeout>
onUnmounted(() => clearTimeout(paginationTimeout))
const pagination = reactive(JSON.parse(JSON.stringify(paginationInitState)))

function loadMore() {
	pagination.status = 'pending'
	hybridSearch()
}

const { data: hybridData, status: hybridStatus, error: hybridError, execute: hybridSearch } = await useAsyncData(
	() => $fetch<HybridSearchResponse>(`/api/ai/hybrid-search`, {
		method: 'post',
		body: {
			query: search.value,
			filters: _filters.value,
			sort_by: sortHybridSearchBy.value,
			user_id: session.value.user.id,
			size: pagination.perPage,
			exclude: pagination.exclude,
		},
		timeout: 7000
	}),
	{
		transform(data): HybridSearchResponse {
			if (data.assets.length < pagination.perPage) pagination.noMoreResults = true

			pagination.exclude.push(...(data.assets || []).map(o => o.asset_id))

			function concatPaginationResults() {
				return {
					assets: [...hybridData.value.assets, ...data.assets],
					datasource_counts: {
						google_drive: Number(hybridData.value?.datasource_counts?.google_drive || 0) + Number(data?.datasource_counts?.google_drive || 0)
					}
				} as HybridSearchResponse
			}

			if (pagination.status === 'pending') {
				pagination.status = 'idle';
				const arr = concatPaginationResults()
				clearTimeout(paginationTimeout)
				paginationTimeout = setTimeout(() => {
					document.querySelector('[data-item="default-layout-parent-el"]').scrollTo({ top: 999999, behavior: 'smooth' });
				}, arr.assets.length * SEARCH_ITEMS_ANIMATION_DELAY_MS)
				return arr;
			}

			return data
		},
		server: false,
		immediate: false,
		lazy: true,
	}
)

watch(() => _filters.value, () => {
	generativeSearch()
	hybridSearch()
}, {
	deep: true,
})

watch(() => sortHybridSearchBy.value, () => {
	hybridSearch()
})

const showResults = ref(false)

async function runSearch(val: string) {
	search.value = val;
	(document as any).activeElement.blur();
	viewMode.value = search.value.trim()?.length ? 'results' : 'search-only'

	searchHistoryState.exitPreview()

	if (viewMode.value === 'search-only') {
		resetSearch()
		return
	}

	setTimeout(() => {
		generativeSearch()
		hybridSearch()
	}, 500)
}

const waitFor = (t: number) => new Promise((resolve) => setTimeout(() => resolve(true), t))

watch(() => viewMode.value, async (newVal, oldVal) => {
	if (oldVal === 'search-only' && newVal === 'results') {
		await waitFor(600)
		showResults.value = true
	}
})

const containerWidth = computed(() => ({
	'w-[650px]': viewMode.value === 'search-only',
	'search-container': viewMode.value === 'results'
}))

const searchDisabled = computed(() => userState.value.processingStatusLoading)
const searchIsNotAllowed = computed(() => !userState.value.processingStatus.hasSucceededAsset)

const generativeSearchIsLoading = computed(() => viewMode.value === 'results' && (generativeSearchStatus.value === 'idle' || generativeSearchStatus.value === 'pending'))
const hybridSearchIsLoading = computed(() => viewMode.value === 'results' && (hybridStatus.value === 'idle' || hybridStatus.value === 'pending'))

function resetSearch() {
	hybridData.value = null
	generativeSearchData.value = null
	showResults.value = false
	filters.date.start = ''
	filters.date.end = ''
	filters.mimeTypes = []
	search.value = ''
	searchHistoryState.exitPreview()
	Object.assign(pagination, paginationInitState)
}

const sortIsDisabled = computed(() => !hybridData.value?.assets?.length)
const filtersAreDisabled = computed(() => JSON.stringify(filtersInitState) === JSON.stringify(filters) && !hybridData.value?.assets?.length && !formattedData.value?.sources?.length)

watch(() => [hybridStatus.value, generativeSearchStatus.value, formattedData.value && hybridData.value], () => {
	const readyToInsertNewHistory = !searchHistoryState.historyPreview.value && formattedData.value && hybridData.value && hybridStatus.value === 'success' && generativeSearchStatus.value === 'success'

	if (readyToInsertNewHistory) {
		searchHistoryState.add({
			uuid: formattedData.value.trace_id,
			userId: session.value.user.id,
			searchQuery: search.value,
			generativeAiResponse: formattedData.value,
			searchResponse: hybridData.value
		})
	}
}, {
	deep: true
})

watch(() => formattedData.value, () => {
	const readyToUpdateGenerativeSearch = searchHistoryState.historyPreview.value && formattedData.value && generativeSearchStatus.value === 'success'
	if (!readyToUpdateGenerativeSearch) return

	const i = searchHistoryState.state.value.findIndex(o => o.uuid === searchHistoryState.historyPreview.value.uuid)
	searchHistoryState.state.value[i].generativeAiResponse = formattedData.value
}, {
	deep: true
})

watch(() => searchHistoryState.historyPreview.value, async () => {
	if (!searchHistoryState.historyPreview.value) return

	search.value = searchHistoryState.historyPreview.value.searchQuery
	hybridStatus.value = 'idle'
	generativeSearchStatus.value = 'idle'
	viewMode.value = 'results'
	await waitFor(600)
	showResults.value = true
	hybridStatus.value = 'pending'
	generativeSearchStatus.value = 'pending'
	await waitFor(600)

	formattedData.value = searchHistoryState.historyPreview.value.generativeAiResponse
	generativeSearchStatus.value = 'success'
	await waitFor(600)

	hybridData.value = searchHistoryState.historyPreview.value.searchResponse
	hybridStatus.value = 'success'
}, {
	deep: true
})
</script>

<template>
	<div class="container flex flex-col p-10" style="transition: 0.3s" :class="{
		'justify-center': viewMode === 'search-only',
		'h-dvh': viewMode === 'search-only'
	}">
		<div class="flex flex-col mx-auto mb-7" :class="containerWidth" v-auto-animate>
			<div v-if="userState.processingStatusLoading || userState.dataSourceLoading" data-testid="searchLoadingSkeleton"
				class="flex flex-col items-center justify-center">
				<Skeleton class="w-2/3 rounded-full" style="height: 39px"></Skeleton>
				<Skeleton class="mt-3 mb-6 w-1/2 rounded-full" style="height: 20px"></Skeleton>
				<Skeleton class="rounded-full w-full" style="height: 50px"></Skeleton>
			</div>
			<template v-else>
				<template v-if="viewMode === 'search-only'">
					<div class="mx-auto text-center mb-8">
						<img src="/logo-symbol.svg" alt="logo" class="h-[70px] w-auto">
					</div>
					<h1 class="text-3xl font-semibold text-center">
						What can I help you find today?
					</h1>
					<h2 class="text-base mt-2 mb-11 text-center text-gray-700">
						Search across multiple data sources using AI.
					</h2>
				</template>
				<LazySearchInput :disabled="searchIsNotAllowed || searchDisabled" :search-value="search" :view-mode="viewMode"
					@run-search="runSearch"></LazySearchInput>
				<span v-if="searchIsNotAllowed" class="text-center mt-4 text-gray-700 block w-full text-sm">
					Search is unavailable. No files yet or still processing. Please check back soon.
				</span>
				<LazySearchHistory v-if="viewMode === 'search-only'" />
			</template>
		</div>
		<div v-if="showResults" class="mx-auto" :class="containerWidth">
			<LazySearchFilters v-if="viewMode === 'results'" :results-count="hybridData?.assets.length || 0" class="fadeUp"
				style="animation-delay: 300ms" @date="filters.date = $event" @mime-types="filters.mimeTypes = $event"
				:disable-all-filters="filtersAreDisabled" />
			<div class="flex gap-8 w-full">
				<div class="flex-grow" style="max-width: calc(1052px - 270px - 32px);">
					<data class="w-full" v-auto-animate>
						<LazyResponseFailure v-if="generativeSearchError && !generativeSearchIsLoading"
							:message="'Something went wrong with the AI search!'"
							:description="`We couldn't process your request. The AI might be taking a break. Try again and let’s see if it gets back on track.`"
							:retry-text="`Retry AI Search`" @retry="generativeSearch()">
						</LazyResponseFailure>
						<template v-else>
							<LazySearchGenerativeLoading v-if="generativeSearchIsLoading" class="mx-auto fadeUp"
								style="animation-delay: 600ms" />
							<LazySearchGenerative v-if="!generativeSearchIsLoading && formattedData" :content="formattedData"
								@refresh="generativeSearch" class="mx-auto" />
						</template>
					</data>

					<LazySearchSortBar v-if="hybridSearchIsLoading || hybridData || hybridError" :skeleton="hybridSearchIsLoading"
						class="mt-9 mb-6 fadeUp" :results-count="hybridData?.assets?.length || 0" style="animation-delay: 1000ms"
						:disable-sort="sortIsDisabled" @sort-by="sortHybridSearchBy = $event">
					</LazySearchSortBar>

					<div class="fadeUp" style="animation-delay: 1100m">
						<LazySearchItem v-for="(n, i) in pagination.status !== 'pending' && hybridSearchIsLoading ? 3 : 0"
							class="w-full mb-6 pb-4 fadeUp"
							:style="{ animationDelay: `${sortHybridSearchBy ? (i + 1) : (i + 3) * SEARCH_ITEMS_ANIMATION_DELAY_MS}ms` }"
							:skeleton="true" />
						<LazySearchItem v-for="(asset, i) in hybridData?.assets ? hybridData.assets : []" :data="asset"
							class="w-full mx-auto mb-6 pb-4 fadeUp"
							:style="{ animationDelay: `${(i + 1) * SEARCH_ITEMS_ANIMATION_DELAY_MS}ms` }" />
						<LazySearchEmptyResults v-if="!hybridSearchIsLoading && !hybridError && !hybridData?.assets?.length"
							:search-query="search" class="fadeUp" style="animation-delay: 100ms" />
						<LazyResponseFailure v-if="hybridError && !hybridSearchIsLoading"
							:message="`Our search encountered an issue!`"
							:description="`Looks like we’re having trouble combining the results. Give it another shot and we’ll sort it out.`"
							@retry="hybridSearch()">
						</LazyResponseFailure>
						<div v-if="hybridData?.assets?.length >= pagination.perPage" class="w-full mt-7 text-center mb-20 fadeUp"
							:style="{ animationDelay: `${hybridData?.assets?.length * SEARCH_ITEMS_ANIMATION_DELAY_MS}ms` }">
							<LazyButton @click="loadMore" :disabled="hybridSearchIsLoading"
								class="gap-2 ps-3 pe-4 flex items-center mx-auto bg-gray-50 h-8" variant="outline">
								<LazyIconLoader v-if="hybridSearchIsLoading" class="w-4 h-4 animate-spin" />
								<LazyIconPlus v-else class="w-4 h-4"></LazyIconPlus>
								<span>Load more</span>
							</LazyButton>
						</div>
					</div>
				</div>
				<div v-if="viewMode === 'results'" class="min-w-[270px] w-[270px]">
					<div v-if="hybridSearchIsLoading" class="w-full fadeUp" style="animation-delay: 400ms">
						<LazySkeleton class="w-[150px] h-5 mb-5" />
						<LazySkeleton class="w-full h-9" />
						<LazySkeleton class="w-full h-9 mt-3" />
					</div>
					<div v-else class="w-full">
						<span class="text-sm text-gray-400 mb-5 block">
							Found {{ hybridData?.assets?.length || 0 }} Results
						</span>
						<div class="rounded-xl h-9 flex items-center gap-3 px-3 bg-indigo-50 text-indigo-600">
							<LazyIconSearch class="w-5 h-5" />
							<span class="text-sm font-medium">All</span>
							<span class="text-xs ms-auto">{{ hybridData?.assets?.length || 0 }}</span>
						</div>
						<div class="flex items-center gap-3 mt-3 h-9 px-3">
							<LazyNuxtIcon class="text-xl" name="google-drive" />
							<span class="text-sm text-gray-900 font-medium">Google Drive</span>
							<span class="text-xs ms-auto text-gray-500">{{ hybridData?.assets?.length || 0 }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import './index.scss';

.search-container {
	animation: widthChange 0.5s ease forwards;
	width: 650px;
}

@keyframes widthChange {
	from {
		width: 650px;
	}

	to {
		width: 1052px;
	}
}
</style>